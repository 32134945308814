import React from "react";

export default function PrimaryButton(props) {
    const styles = {
        container: {
            background: props.background || '#2192F0',
            display: 'flex',
            flewDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: props.borderRadius || '12px',
            height: props.height || '54px',
            cursor: 'pointer'
        },
        text: {
            fontSize: '17px',
            lineHeight: '22px',
            color: 'white',
            fontWeight: '600',
            textAlign: 'center'
        }
    }

    return (
        <div className="container" style={styles.container} onClick={props.onClick}>
            <div className="text" style={styles.text}>
                {props.text}
            </div>
        </div>
    )
}