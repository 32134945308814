async function trackEventFB(event, options) {
    console.log('FB', event);
    return window.fbq('trackCustom', event, options);
}

async function trackEventGA(event, options) {
    console.log('GA', event);
    return window.gtag('event', event, options);
}

export default async function trackEvent(event, options) {
    try {
        await trackEventFB(event, options)
    }
    catch (e) {
        console.log('Can`t track FB event:', e)
    }

    try {
        await trackEventGA(event, options)
    }
    catch (e) {
        console.log('Can`t track GA event:', e)
    }

}