import React from "react";
import OutlinedButton from "./OutlinedButton";

export default function QuestionBlock(props) {

    const styles = {
        title: {

            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '17px',
            lineHeight: '22px',
            marginBottom: '16px',
            letterSpacing: '0.38px',
            textAlign: 'center'
        }
    }

    return (


        <div className="wrapper">
            <div className="title" style={styles.title}>
                {props.question.title}
            </div>
            <div className="buttons">
                {props.question.answers.map((e, index) => <div style={{ marginTop: index === 0 ? '0px' : '16px' }}><OutlinedButton text={e.title} onClick={() => e.onClick(e.data, e.id)} /></div>)}
            </div>
        </div>
    )
} 