import './App.css';
import AgeGroupPage from './ui/views/onboardings/AgeGroupPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const styles = {
  wrapper: {},
}

function App() {
  return (
    <div className="wrapper" style={styles.wrapper}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AgeGroupPage />} />
          <Route path="/onboarding/ageGroup" element={<AgeGroupPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
