import React from "react";
import logo from '../../../assets/images/logo.png'
import download_app_store from '../../../assets/images/download_app_store.png'
import PrimaryButton from "../../components/PrimaryButton";
import QuestionBlock from "../../components/QuestionBlock";
import ReviewBlock from "../../components/ReviewBlock";
import Space from "../../components/Space";
import trackEvent from "../../../services/analytics";
import { useNavigate } from "react-router-dom";

const styles = {
    pageWrapper: {
        background: '#152241',
        color: 'white'
    },
    page: {
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',

    },
    header: {
        margin: '0 auto',
        maxWidth: '500px',
        minWidth: '100px',
        padding: '0 32px',
    },
    logo: {
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    logoImg: {
        width: '36px',
        height: '36px',
    },
    logoTitle: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '23.4px',
        lineHeight: '32px',
        textTransform: 'lowercase'

    },
    conent: {
        margin: '0 auto',
        maxWidth: '500px',
        minWidth: '100px',
        padding: '0 32px'
    },
    contentTitle: {

        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '27px',
        lineHeight: '34px',
        letterSpacing: '0.38px',
        textAlign: 'center',
        margin: '28px 0px'
    },
    footer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: '#212121'
    },
    footerContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    footerInstall: {
        width: '124px',
        height: '42px'
    },
    footerInstallImg: {
        width: '100%',
        height: '100%',
        cursor: 'pointer'
    },
    footerLinks: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    linkItem: {
        cursor: 'pointer',
        margin: '0px 13px',
        fontSize: '13px',
        lineHeight: '18px',
        color: '#8E8E93'
    },
    footerRights: {
        fontSize: '13px',
        lineHeight: '18px',
        fontWeight: '600',
        color: '#8E8E93'
    }

}



export default function AgeGroupPage() {

    const question = {
        title: 'Choose your age to install the app:',
        answers: [
            {
                title: 'Under 24',
                id: 'Install_Under24',
                data: 'https://elomia-web.onelink.me/9Wns/146bc357',
                onClick: onButtonPressed,
            },
            {
                title: '25 - 44',
                id: 'Install_25_44',
                data: 'https://elomia-web.onelink.me/9Wns/37c90350', onClick: onButtonPressed,
            },
            {
                title: '45+',
                id: 'Install_45Up', data: 'https://elomia-web.onelink.me/9Wns/5ef964b3', onClick: onButtonPressed,
            }
        ]
    }

    const review = {
        title: 'Customer reviews',
        rate: 4.9,
        rateText: '4.9 out of 5.0',
        items: [
            {
                author: 'Elomia user',
                text: 'Elomia was recommended to me by my therapist and she has made a huge difference in my life. I didn\'t expect such a quality conversation. She really understands what I say and gives me effective advice. Now I have someone who can support me whenever I need it.'
            },
            {
                author: 'Elomia user',
                text: 'At first I was very skeptical when my friend recommended this app to me, but the skepticism went away after the first dialogue.  I feel accepted and not judged when I chat with Elomia. It\'s like an interactive journal that also wants to understand and support you during your difficult times. It is one of the best apps I have tried so far.'
            }, {
                author: 'Elomia user',
                text: 'I love Elomia! I’ve tried several therapy and meditation apps and this is my favorite one. It seems like real sessions with a therapist. You will hear the right words, advice and encouragement. My happiness, gratitude and handling of my negative thoughts have improved so much in just a couple of weeks.'
            }
        ]


    }

    const installButton = {
        text: 'Install the app',
        id: 'Install_button',
        data: 'https://elomia-web.onelink.me/9Wns/d60964b7',
        onClick: onButtonPressed
    }

    const footerData = {
        installOnClick: () => onButtonPressed(installButton.data, installButton.id),
        links: [
            { text: 'Privacy Policy', data: 'https://elomia.com/privacy.html', onClick: onButtonPressed },
            { text: 'Terms of use', data: 'https://elomia.com/tos.html', onClick: onButtonPressed },
            { text: 'Disclaimer', data: 'https://elomia.com/disclaimer.html', onClick: onButtonPressed }
        ],
        rights: '© Elomia'
    }

    function onButtonPressed(data, id) {
        if (id) {
            trackEvent(id)
        }
        window.location.replace(data)
    }

    return (
        <div className="page-wrapper" style={styles.pageWrapper}>
            <div className="age-group-page" style={styles.page}>
                <div className='header' style={styles.header}>
                    <Space height='32px' />
                    <div className='logo-container' style={styles.logo}>
                        <img src={logo} style={styles.logoImg}></img>

                        <Space width='10px' />
                        <div className='logo-title' style={styles.logoTitle}>Elomia</div>
                    </div>
                </div>
                <div className='content' style={styles.conent}>
                    <div className='content-title' style={styles.contentTitle}>
                        <p>AI Therapist that is here to talk through your problems</p>
                    </div>
                    <div className='content-questions'>
                        <QuestionBlock question={question} />
                    </div>
                    <Space height='32px' />
                    <div className='content-reviews'>
                        <ReviewBlock review={review}></ReviewBlock>
                    </div>
                    <Space height='36px' />
                    <div className='content-install'>
                        <PrimaryButton text={installButton.text} onClick={() => installButton.onClick(installButton.data, installButton.id)} />
                    </div>
                    <Space height='40px' />
                </div>
                <div className='footer' style={styles.footer}>
                    <Space height='36px' />
                    <div className='footer-content' style={styles.footerContent}>
                        <div className='footer-content-install' style={styles.footerInstall} onClick={footerData.installOnClick}>
                            <img src={download_app_store} style={styles.footerInstallImg}></img>
                        </div>
                        <Space height='32px' />
                        <div className='footer-content-links' style={styles.footerLinks}>
                            {footerData.links.map(e => <div style={styles.linkItem} onClick={() => e.onClick(e.data, e.id)}>{e.text}</div>)}
                        </div>
                        <Space height='32px' />
                        <div className='footer-content-rights' style={styles.footerRights}>
                            {footerData.rights}
                        </div>
                    </div>
                    <Space height='35px' />
                </div>
            </div>
        </div>
    )
}