import React from "react";

export default function OutlinedButton(props) {
    const styles = {
        container: {
            background: props.background || '#29395F',
            border: props.border ? `${props.border.width || '0px'} ${props.border.style || 'solid'} ${props.border.color || 'black'}` : '1px solid #43598E',
            borderRadius: props.borderRadius || '12px',
            height: props.height,
            padding: '24px 16px',
            cursor: 'pointer'
        },
        text: {
            fontSize: '16px',
            lineHeight: '21px',
            color: 'white'
        }
    }

    return (
        <div className="container" style={styles.container} onClick={props.onClick}>
            <div className="text" style={styles.text}>
                {props.text}
            </div>
        </div>
    )
}