import React from "react";
import StarRatings from "react-star-ratings";
import Space from "./Space";


export default function ReviewBlock(props) {
    const styles = {
        title: {
            
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '20px',
            lineHeight: '34px',
            textAlign: 'center',
        },
        rate: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
        },
        rateText: {
            
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '17px',
            lineHeight: '22px',
        },
        reviewCard: {
            background: '#0A1226',
            borderRadius: '14px',
            padding: '20px 22px 28px 22px'
        },
        author: {
            
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '17px',
            lineHeight: '22px',
        },
        text: {
            
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '17px',
            lineHeight: '22px',
        }
    }
    let review = props.review;
    function ReviewCard(_props) {
        return (
            <div className="reviewCard" style={styles.reviewCard}>
                <div style={styles.author}>{_props.author}</div>
                <Space height='20px' />
                <div style={styles.text}>{_props.text}</div>
            </div>
        )
    }
    return (<div>
        <div className="title" style={styles.title}>
            {review.title}
        </div>
        <Space height='8px' />
        <div className="rate" style={styles.rate}>
            <StarRatings
                rating={review.rate}
                starRatedColor="#FFB74B"
                starDimension="22px"
                starSpacing="3.5px"
                name='rating'
            />
            <Space width='15px' />
            <div className="rateText" style={styles.rateText}>{review.rateText}</div>


        </div>
        <Space height='32px' />
        {review.items.map((e, index) => <div style={{ marginTop: index === 0 ? '0px' : '16px' }}> <ReviewCard {...e} /></div>)
        }
    </div >)
}